// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `оружие` - Weapon
 * * `нож` - Knife
 * * `перчатки` - Gloves
 * * `наклейка` - Sticker
 * * `агент` - Agent
 * @export
 */
export const CategoryEnum = {
  1: "оружие",
  2: "нож",
  3: "перчатки",
  4: "наклейка",
  5: "агент",
} as const;
export type CategoryEnum = (typeof CategoryEnum)[keyof typeof CategoryEnum];

export function CategoryEnumFromJSON(json: any): CategoryEnum {
  return CategoryEnumFromJSONTyped(json, false);
}

export function CategoryEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CategoryEnum {
  return json as CategoryEnum;
}

export function CategoryEnumToJSON(value?: CategoryEnum | null): any {
  return value as any;
}
